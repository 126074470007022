import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAdminService } from '@api';
import { useState } from 'react';
import { TUserLog, TUserLogFilter } from '@xeppt/xeppt-sdk/types/admin';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useLocales } from '@hooks/helpers/useLocales';
import { toast } from 'react-toastify';

interface IProps {
    consumerId?: string;
}

export const useLogs = ({ consumerId }: IProps) => {
    const { requestErrorLocale } = useLocales();
    const [logsFilter, setLogsFilter] = useState<TUserLogFilter>({});
    const [logsCursor, setLogsCursor] = useState<Date>();
    const [logs, setLogs] = useState<TUserLog[]>([]);

    const { data: logsData, handleRequest: handleGetLogs } = useApiQuery({
        method: (request?: any) =>
            apiAdminService.getUserLogs(
                {
                    ...request?.filter,
                    level: request?.filter?.level?.length > 0 ? request?.filter?.level : undefined,
                    consumerId: consumerId || request?.filter?.consumerId
                },
                { limit: 100, cursor: request?.isNew ? undefined : logsCursor }
            ),
        isInitialRequest: false,
        onSuccess: (data, request?: any) => {
            if (logsCursor !== data.cursor && logsCursor && !request?.isNew) {
                setLogs((state) => [...state, ...data.data]);
                setLogsCursor(data.cursor);
            } else {
                setLogs(data.data);
                setLogsCursor(data.cursor);
            }
        }
    });

    const { handleRequest: handleDeleteLog } = useApiMutation({
        method: (id: string) => apiAdminService.deleteUserLog(id),
        onError: requestErrorLocale,
        onSuccess: () => {
            toast.success('Log was deleted');
        }
    });

    const onChangeFilter = (data: Partial<typeof logsFilter>) => {
        console.log(data);
        setLogsCursor(undefined);
        setLogsFilter((state) => ({ ...state, ...data }));
        setLogs([]);
        handleGetLogs({ isNew: true, filter: { ...logsFilter, ...data } });
    };

    return {
        logs,
        logsData,
        handleGetLogs,
        handleDeleteLog,
        logsFilter,
        logsCursor,
        onChangeFilter
    };
};
