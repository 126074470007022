import { useCallback, useEffect, useRef, useState } from 'react';
import useModalContext from '@hooks/context/useModalContext';
import { useUserContext } from '@hooks/context/useUserContext';
import { modalIds } from '@const/modals';

const LAST_ACTIVITY_KEY = 'lastActivityTime';

export const useUserActivity = () => {
    const { onOpen, onClose, changeModalData } = useModalContext();
    const { handleLogout } = useUserContext();
    const [showModal, setShowModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState(30);
    const timerRef = useRef<number | null>(null);
    const countdownRef = useRef<number | null>(null);
    const [logoutInterval, setLogoutInterval] = useState<any>();
    const [isFirstRender, setIsFirstRender] = useState(true);

    const resetTimer = useCallback((): void => {
        const now = new Date().getTime();
        localStorage.setItem(LAST_ACTIVITY_KEY, now.toString());

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setTimeLeft(30);
        countdownRef.current = window.setInterval(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        // Only start the timer if the modal is not shown
        if (!showModal) {
            timerRef.current = window.setTimeout(() => {
                setShowModal(true);
            }, 870000); // 870000ms = 14 minutes 30 seconds
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        }
    }, [showModal]);

    const handleUserActivity = () => {
        setShowModal(false); // Hide modal if it's showing
        resetTimer();
    };

    const checkLastActivity = () => {
        const lastActivityTime = localStorage.getItem(LAST_ACTIVITY_KEY);
        if (lastActivityTime && !isFirstRender) {
            const now = new Date().getTime();
            const elapsed = now - parseInt(lastActivityTime, 10);

            if (elapsed > 900000) {
                // 900000ms = 15 minutes
                clearInterval(logoutInterval);
                onClose();
                handleLogout();
            }
        }
        setIsFirstRender(false);
    };

    useEffect(() => {
        const interval = setInterval(checkLastActivity, 1000);
        setLogoutInterval(interval);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        checkLastActivity();
        window.addEventListener('mousedown', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);
        window.addEventListener('scroll', handleUserActivity);
        resetTimer();
        return () => {
            window.removeEventListener('mousedown', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            if (countdownRef.current) {
                clearInterval(countdownRef.current);
            }
        };
    }, [resetTimer]);

    useEffect(() => {
        if (showModal) {
            window.removeEventListener('mousedown', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
        } else {
            window.addEventListener('mousedown', handleUserActivity);
            window.addEventListener('keypress', handleUserActivity);
            window.addEventListener('scroll', handleUserActivity);
            resetTimer();
            setShowModal(false);
        }
    }, [showModal]);

    useEffect(() => {
        if (showModal) {
            onOpen({
                modalId: modalIds.USER_ACTIVITY_MODAL,
                onSubmit: () => ({
                    logout: () => {
                        handleLogout();
                        onClose();
                    },
                    cancel: () => {
                        if (countdownRef.current) {
                            clearInterval(countdownRef.current);
                        }
                        if (timerRef.current) {
                            clearInterval(timerRef.current);
                        }
                        setTimeLeft(30);
                        handleUserActivity();
                        onClose();
                    }
                })
            });
        }
    }, [showModal, timeLeft]);

    useEffect(() => {
        if (timeLeft < 30) {
            changeModalData({ timer: timeLeft });
        }
        if (timeLeft <= 0) {
            onClose();
            handleLogout();
        }
    }, [timeLeft]);
};
